
import Vue from 'vue'
export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'CategoryFilterDialog',
  props: {
    saleRate: {
      type: Array,
      default: () => [],
    },
    min: {
      type: Number,
      default: 0,
    },
    max: {
      type: Number,
      default: 0,
    },
    gtagName: {
      type: String,
      default: '',
    },
    showSheet: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      range: [this.min, this.max],
      saleRateIndex: 0,
      saleRateData: {},
      priceData: {},
      btnIndex: -1,
      saleRateBtn: true,
      test: this.showSheet,
    }
  },
  created() {
    if (this.gtagName) this.$tracking(this.gtagName)
  },
  computed: {
    minText() {
      const maxLength = this.max.toString().length
      if (maxLength <= 4) {
        return (
          Math.floor(this.range[0] / Math.pow(10, maxLength - 1)) *
          Math.pow(10, maxLength - 1)
        )
      } else {
        return (
          Math.floor(this.range[0] / Math.pow(10, maxLength - 2)) *
          Math.pow(10, maxLength - 2)
        )
      }
    },
    maxText() {
      const maxLength = this.max.toString().length
      if (maxLength <= 4) {
        return (
          Math.ceil(this.range[1] / Math.pow(10, maxLength - 1)) *
          Math.pow(10, maxLength - 1)
        )
      } else {
        return (
          Math.ceil(this.range[1] / Math.pow(10, maxLength - 2)) *
          Math.pow(10, maxLength - 2)
        )
      }
    },
    applyFilterBtn(): boolean {
      return this.btnIndex > -1
        ? false
        : (true && this.range[0] != this.min) || this.range[1] != this.max
        ? false
        : true
    },
  },
  methods: {
    onSubmit() {
      if (this.gtagName) this.$tracking(`${this.gtagName}_apply`)
      this.$emit('onSubmit', this.saleRateData, this.priceData)
      this.saleRateBtn = true
      this.reset()
    },
    close() {
      if (this.gtagName) this.$tracking(`${this.gtagName}_close`)
      this.$emit('close')
    },
    onClickBtnSaleRate(index: number) {
      this.btnIndex === index ? (this.btnIndex = -1) : (this.btnIndex = index)

      if (this.btnIndex > -1) {
        const { filter, title } = this.saleRate[this.btnIndex]
        const minSaleRate = filter['filters.minSaleRate']
        const maxSaleRate = filter['filters.maxSaleRate']
        if (this.gtagName) {
          this.$tracking(
            `${this.gtagName}_${index ? minSaleRate : maxSaleRate}`
          )
        }
        this.saleRateData = {
          minSaleRate,
          maxSaleRate,
          title,
          saleRateIndex: index,
        }

        this.saleRateIndex = index
      } else {
        this.saleRateData = {}
      }
    },
    onChangeRangeSlider() {
      if (this.range[0] != this.min || this.range[1] != this.max) {
        this.saleRateBtn = false
        this.priceData = {
          minPrice: this.minText,
          maxPrice: this.maxText,
        }
      } else {
        this.saleRateBtn = true
        this.priceData = {}
      }
    },
    reset(): void {
      if (this.gtagName) this.$tracking(`${this.gtagName}_renew`)
      this.saleRateData = {}
      this.priceData = {}
      this.range = [this.min, this.max]
      this.btnIndex = -1
    },
  },
})

interface Data {
  range: number[]
  saleRateIndex: number
  saleRateData: CategorySaleFilter | object
  priceData: CategoryPriceFilter | object
  btnIndex: number
  saleRateBtn: boolean
}
interface Methods {
  onSubmit(): void
  close(): void
  onClickBtnSaleRate(index: number): void
  onChangeRangeSlider(): void
  reset(): void
}
interface Computed {
  minText: number
  maxText: number
  applyFilterBtn: boolean
}
interface Props {
  min: number
  max: number
  saleRate: Array<SaleRate>
  gtagName: string
  showSheet: boolean
}
