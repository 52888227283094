
import Vue, { PropType } from 'vue'
import CategoryFilterDialog from './CategoryFilterDialog.vue'
import { initCategoryHistory } from '@/util/category'
import { OptionFilterList, OptionFilterListFilter } from 'types/App'
export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'CategoryFilter',
  components: {
    CategoryFilterDialog,
  },
  props: {
    top: {
      type: String,
      default: '5.4em',
    },
    filterList: {
      type: [] as PropType<OptionFilterList[]>,
      default: () => [],
    },
    filterTab: {
      type: Number,
      default: () => 2,
    },
    filterHelper: {
      type: Object,
      default: () => initCategoryHistory.categoryFilterHelper,
    },
    chipsArr: {
      type: Array as () => string[],
      default: () => [],
    },
    gtagName: {
      type: String,
      default: '',
    },
    showFilter: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showSheet: false,
    }
  },
  computed: {
    fixedBarFilter() {
      return `position: sticky; top:${this.top}; z-index: 2; background-color: white;`
    },
  },
  methods: {
    handleChangeFilter(index: number, sortOption: OptionFilterListFilter) {
      this.showSheet = false
      this.$emit('filterTabChange', index, sortOption)
    },
    onSubmitFilter(saleRateData: any, priceData: any): void {
      this.showSheet = false
      this.$emit('onSubmitFilter', saleRateData, priceData)
    },
    deleteChipArea(key: string) {
      this.$emit('deleteChipArea', key)
    },
    closeCategoryFilterDialog() {
      this.showSheet = false
    },
  },
})

interface Data {
  showSheet: boolean
}
interface Methods {
  handleChangeFilter(index: number, sortOption: OptionFilterListFilter): void
  onSubmitFilter(
    saleRateData: CategorySaleFilter,
    priceData: CategoryPriceFilter
  ): void
  deleteChipArea(key: string): void
  closeCategoryFilterDialog(): void
}
interface Computed {
  fixedBarFilter: string
}
interface Props {
  top: string
  filterList: OptionFilterList[]
  filterTab: number
  filterHelper: CategoryFilterHelper
  chipsArr: string[]
  gtagName: string
  showFilter: boolean
}
