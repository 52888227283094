
import Vue from 'vue'
import { mapActions, mapGetters } from 'vuex'
import CategoryProductListView from '@/components/views/category/CategoryProductList.vue'
import { getHistory } from '@/helper/category'
import { OptionFilterList } from 'types/App'

export default Vue.extend<Data, Methods, Computed>({
  name: 'CategoryProductList',
  components: {
    CategoryProductListView,
  },
  created() {
    this.fetchData()
  },
  computed: {
    ...mapGetters('ProfileStore', ['getDefaultFilterIndex', 'getFilterList']),
  },
  methods: {
    ...mapActions('CategoryStore', [
      'fetchCategoryMain',
      'fetchInitCategoryHistory',
      'fetchCategoryHistory',
    ]),
    async fetchData() {
      const history: CategoryHistory | null = getHistory()
      if (history && history.page.currentPage === this.$route.path.slice(1)) {
        this.fetchCategoryHistory(history)
      } else {
        //스토어 초기화 호출
        this.fetchInitCategoryHistory()
        this.fetchCategoryMain()
        const categoryFilter = this.getDefaultFilterIndex ?? 2
        const { filter } = this.getFilterList[categoryFilter]
        const { categoryCode } = this.$route.query
        const payload: CategoryHistoryPayload = {
          page: {
            categoryCode: String(categoryCode),
            categoryFilter,
            sortOption: filter['sort.option'] ?? 'latest',
          },
        }
        this.fetchCategoryHistory(payload)
      }
    },
  },
})

interface Data {}
interface Methods {
  // Store
  fetchInitCategoryHistory(): Promise<void>
  fetchCategoryHistory(payload: CategoryHistoryPayload): Promise<void>
  fetchCategoryMain(): Promise<void>
  // Component
  fetchData(): void
}
interface Computed {
  getDefaultFilterIndex: number
  getFilterList: OptionFilterList[]
}
