
import Vue from 'vue'
import { mapGetters, mapActions } from 'vuex'
import CategoryBestProductSlide from '@/components/views/category/common/CategoryBestProductSlide.vue'
import CategoryHeader from '@/components/views/category/common/CategoryHeader.vue'
import CategoryTab from '@/components/views/category/common/CategoryTab.vue'
import CategoryFilter from '@/components/views/category/common/CategoryFilter.vue'
import CategoryProduct from '@/components/views/category/common/CategoryProduct.vue'
import { GetCategoryProduct } from '@/api/displayAPI/CategoryAPI'
import { AppProfile, OptionFilterList } from 'types/App'
import { initCategoryHistory } from '@/util/category'

export default Vue.extend<Data, Methods, Computed>({
  name: 'CategoryProductListView',
  components: {
    CategoryHeader,
    CategoryTab,
    CategoryBestProductSlide,
    CategoryFilter,
    CategoryProduct,
  },
  data() {
    return {
      dataFetchComplete: false,
      chipsArr: [],
      flag: false,
    }
  },
  async created() {
    const { path, query } = this.$route
    this.fetchCategoryHistory({
      page: {
        currentPage: path.slice(1),
        categoryCode: String(query.categoryCode),
      },
    })
    this.saveCategoryIndex()
    this.initFetch()
  },
  computed: {
    ...mapGetters('ProfileStore', [
      'getProfile',
      'getDefaultFilterIndex',
      'getFilterList',
    ]),
    ...mapGetters('CategoryStore', ['getCategoryHistory']),
    /**
     * @return
     * 1. 전체 카테고리면 최상위 카테고리 리턴
     * 2. 카테고리 코드가 맞는데
     * 1차카테고리면 2차 카테고리리스트 리턴
     * 2차카테고리면 하위 카테고리 리스트가 없으므로 1차 카테고리리스트 리턴
     */
    categoryList() {
      if (!this.getProfile?.category?.categoryList) return []

      const { categoryList } = this.getProfile.category
      if (this.targetCategoryCode === '7') return categoryList

      const firstCategory = categoryList.find(
        (value) => value.categoryCode === this.targetCategoryCode
      )

      if (firstCategory && firstCategory.categoryList.length > 0) {
        const { categoryCode } = firstCategory.categoryList[0]
        this.fetchCategoryHistory({
          page: { categoryCode },
        })

        return firstCategory.categoryList
      }

      const category: CategoryItem | undefined = categoryList.find(
        (item: CategoryItem) => {
          return item.categoryList.some((value) => {
            return value.categoryCode === this.targetCategoryCode
          })
        }
      )
      return category ? category.categoryList : []
    },
    targetCategoryCode() {
      return String(this.$route.query.categoryCode)
    },
    targetTitle() {
      const { categoryTab } = this.getCategoryHistory.page
      if (!this.categoryList[categoryTab]?.categoryName) {
        return String(this.$route.query.categoryName)
      }
      return String(this.categoryList[categoryTab]?.categoryName)
    },
    history() {
      return this.getCategoryHistory
    },
  },
  watch: {
    'history.page.categoryDetailFilter': {
      deep: true,
      handler(data) {
        const { priceData, saleRateData } = data
        const res: filterChip[] = []
        if (priceData.maxPrice) {
          res.push({
            key: 'priceData',
            text: `${priceData.minPrice} ~ ${priceData.maxPrice}`,
          })
        }
        if (saleRateData.minSaleRate && saleRateData.maxSaleRate) {
          res.push({
            key: 'saleRateData',
            text: `${saleRateData.title} 할인`,
          })
        }
        this.chipsArr = res
      },
    },
    async isLogined() {
      try {
        this.fetchLoading({ yn: true })
        const initFirstPage = this.history.page.searchList[0].groupKey ?? 0
        const data = await Promise.all([
          this.getListData(initFirstPage),
          this.getListData(initFirstPage + 1),
          this.getListData(initFirstPage + 2),
        ])
        const list = await this.$infiniteRefreshList(data, initFirstPage)

        this.fetchCategoryHistory({
          page: {
            searchList: list,
          },
        })
      } finally {
        this.fetchLoading({ yn: false })
      }
    },
  },

  methods: {
    ...mapActions('LoadingStore', ['fetchLoading']),
    ...mapActions('PullToRefreshStore', ['fetchRefreshNoti']),
    ...mapActions('CategoryStore', ['fetchCategoryHistory']),
    saveCategoryIndex() {
      const index = this.categoryList.findIndex((value) => {
        return value.categoryCode === this.targetCategoryCode
      })

      if (index === -1) {
        return
      }

      this.fetchCategoryHistory({
        page: { categoryTab: index },
      })
    },
    async fetch(type = 'next') {
      this.fetchLoading({ yn: true })

      const {
        paging,
        categoryCode,
        categoryDetailFilter,
        sortOption,
        searchList,
      } = this.history.page
      const { priceData, saleRateData } = categoryDetailFilter

      const { page } = paging
      const params = {
        categoryCode,
        maxPrice: priceData.maxPrice,
        minPrice: priceData.minPrice,
        maxSaleRate: saleRateData.maxSaleRate,
        minSaleRate: saleRateData.minSaleRate,
        page,
        limit: 50,
        sortOption,
      }

      await GetCategoryProduct(params)
        .then(({ data }) => {
          const searchProductList = this.$replaceList(
            searchList,
            data.searchProductList,
            params.page,
            params.limit,
            type
          )

          if (type == 'reset') {
            this.fetchCategoryHistory({
              page: {
                searchList: searchProductList,
                bestSearchList: data.bestSearchProductList,
                paging: data.paging,
                categoryFilterHelper: data.filterHelper,
              },
            })
          } else {
            this.fetchCategoryHistory({
              page: {
                searchList: searchProductList,
                paging: data.paging,
              },
            })
          }
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          this.dataFetchComplete = true
          this.fetchLoading({ yn: false })
        })
    },
    async initFetch() {
      const { searchList } = this.history.page

      if (!searchList.length) {
        this.fetch('reset')
      } else {
        this.dataFetchComplete = true
      }

      try {
        const { categoryName } = this.$route.query
        const { searchList } = this.history.page
        const criteoCategory =
          categoryName === this.targetTitle
            ? this.targetTitle
            : `${categoryName}/${this.targetTitle}`
        const criteoItems = searchList.slice(0, 3).map((item) => item.productNo)
        this.$criteo({
          event: 'viewList',
          category: criteoCategory,
          item: criteoItems,
        })
      } catch (e) {
        console.log('category product list criteo error')
      }
    },
    async scrollFetch(page, type) {
      if (this.flag) return
      this.flag = true
      const { paging } = this.history.page
      this.fetchCategoryHistory({ page: { paging: { ...paging, page } } })

      await this.fetch(type)

      this.flag = false
    },
    async categoryTabChange(tabData, index) {
      this.resetList()
      this.dataFetchComplete = false
      const { categoryCode, categoryName, categoryList } = tabData
      this.$tracking(`cate_${categoryCode}_detail`)
      if (categoryList.length > 0) {
        this.$router.push({
          name: 'CategoryProductList',
          query: {
            categoryCode,
            categoryName,
            previousPage: 'categoryMain',
          },
        })
      } else {
        this.fetchCategoryHistory({
          page: {
            categoryTab: index,
            categoryCode: tabData.categoryCode,
            categoryDetailFilter: {
              priceData: { maxPrice: 0, minPrice: 0 },
              saleRateData: { maxSaleRate: 0, minSaleRate: 0, title: '' },
            },
          },
        })

        this.fetch('reset')
      }
    },
    resetList() {
      const { paging } = this.history.page
      this.fetchCategoryHistory({
        page: {
          searchList: [],
          marginHeight: 0,
          paging: { ...paging, page: 0, limit: 50 },
        },
      })
      this.$getAppHtml.scrollTo({ top: 0 })
    },
    async filterTabChange(filterIndex, sortOption) {
      this.dataFetchComplete = false
      const { categoryCode } = this.history.page
      this.$tracking(`cate_${categoryCode}_detail_${sortOption['sort.option']}`)
      this.resetList()

      //필터 옵션
      this.fetchCategoryHistory({
        page: {
          sortOption: sortOption['sort.option'],
          categoryFilter: filterIndex,
        },
      })
      this.fetch()
    },
    onSubmitFilter(saleRateData, priceData) {
      this.dataFetchComplete = false
      this.fetchCategoryHistory({
        page: {
          categoryDetailFilter: { priceData, saleRateData },
        },
      })
      this.resetList()
      this.fetch()
    },
    deleteChipArea(key: 'priceData' | 'saleRateData') {
      this.dataFetchComplete = false
      const { categoryDetailFilter } = this.history.page
      const data = {
        [key]: {
          ...initCategoryHistory.categoryDetailFilter[key],
        },
      }

      this.fetchCategoryHistory({
        page: {
          categoryDetailFilter: {
            ...categoryDetailFilter,
            ...data,
          },
        },
      })

      this.resetList()
      this.fetch()
    },
    resetDetailFilter() {
      this.fetchCategoryHistory({
        page: {
          categoryDetailFilter: {
            priceData: { maxPrice: 0, minPrice: 0 },
            saleRateData: { maxSaleRate: 0, minSaleRate: 0, title: '' },
          },
        },
      })

      this.resetList()
      this.fetch()
    },
    async handleRefresh() {
      this.dataFetchComplete = false
      const categoryFilter = this.getDefaultFilterIndex ?? 2
      this.fetchCategoryHistory({
        page: {
          categoryFilter,
          sortOption:
            this.getFilterList?.[categoryFilter]?.filter['sort.option'] ??
            'latest',
        },
      })
      await this.resetDetailFilter()
    },
    onClickPrev() {
      const { categoryCode } = this.history.page
      this.$tracking(`cate_${categoryCode}_detail_back`)
    },
    onClickTitle() {
      const { categoryCode } = this.history.page
      this.$tracking(`cate_${categoryCode}_detail_viewall`)
    },
    async getListData(page) {
      try {
        const { categoryCode, categoryDetailFilter, sortOption } =
          this.history.page
        const { priceData, saleRateData } = categoryDetailFilter

        const params = {
          categoryCode,
          maxPrice: priceData.maxPrice,
          minPrice: priceData.minPrice,
          maxSaleRate: saleRateData.maxSaleRate,
          minSaleRate: saleRateData.minSaleRate,
          page,
          limit: 50,
          sortOption,
        }
        const { data } = await GetCategoryProduct(params)

        return data.searchProductList
      } catch (e) {
        return []
      }
    },
  },
})

interface filterChip {
  key: 'priceData' | 'saleRateData'
  text: string
}

interface Data {
  dataFetchComplete: boolean
  chipsArr: any[]
  flag: boolean
}
interface Methods {
  // Store
  fetchLoading(paylaod: { yn: boolean }): Promise<void>
  fetchCategoryHistory(payload: CategoryHistoryPayload): Promise<void>
  //Component
  saveCategoryIndex(): void
  categoryTabChange(tabData: CategoryItem, index: number): void
  resetList(): void
  filterTabChange(
    filterIndex: number,
    sortOption: { 'sort.option': string }
  ): void
  onSubmitFilter(
    saleRateData: CategorySaleFilter,
    priceData: CategoryPriceFilter
  ): void
  deleteChipArea(type: 'priceData' | 'saleRateData'): void
  resetDetailFilter(): void
  handleRefresh(): void
  onClickPrev(): void
  onClickTitle(): void
  fetch(type?: string): void
  initFetch(): void
  scrollFetch(page: number, type: string): void
  getListData(page: number): Promise<ProductItem[]>
}
interface Computed {
  getProfile: AppProfile
  getDefaultFilterIndex: number
  getFilterList: OptionFilterList[]
  getCategoryHistory: CategoryHistory
  categoryList: CategoryItem[]
  targetCategoryCode: string
  targetTitle: string
  history: CategoryHistory
}
