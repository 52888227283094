
import Vue, { PropType } from 'vue'
import Slider from '../../../common/slider/Slider.vue'
import { mapActions, mapGetters } from 'vuex'

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'CategoryBestProductSlide',
  components: {
    Slider,
  },
  props: {
    targetTitle: {
      type: String,
      default: '',
    },
    gtagName: {
      type: String,
      default: '',
    },
  },
  data() {
    return {}
  },
  computed: {
    ...mapGetters('CategoryStore', ['getCategoryHistory']),
  },
  methods: {
    ...mapActions('CategoryStore', ['fetchCategoryBestSellerHistory']),

    isLikeChange(yn: boolean, id: string, index: number) {
      console.log('isLikeChange')
      const copy = [...this.getCategoryHistory.page.bestSearchList][index]
      copy.isLike = yn
      this.fetchCategoryBestSellerHistory({ item: copy, index })

      if (!this.gtagName) return
      const trackingName = `${this.gtagName}_${this.$numbering(index + 1)}`
      if (yn) this.$tracking(`${trackingName}_like`)
      else this.$tracking(`${trackingName}_unlike`)
    },
  },
})

interface Data {}
interface Methods {
  isLikeChange(yn: boolean, id: string, index: number): void
  fetchCategoryBestSellerHistory(payload: {
    item: ProductItem
    index: number
  }): Promise<void>
}
interface Computed {
  getCategoryHistory: CategoryHistory
}
interface Props {
  targetTitle: string
  gtagName: string
}
